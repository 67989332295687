main {
  text-align: center;
  background-color: #ffffff;
  background-image: url('./assets/firefly_holdingPage_bg_copy.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  min-height: 100vh;	
}

.container {
  width: 80vw;
  margin: 0 auto;
}

.holdingPageSection {
  margin: 0 auto;
  color: #ffffff;
  font-size: 30px;
  position: relative;
  top: 28vh;
}

.holdingPageSection img.image1{
  margin: 0 auto;
}

.holdingPageSection img.image2{
  margin: 0 auto;
  display: none;
}

.holdingPageSection p{ 
  text-align: center;
  margin-block-end: 0em;
  margin-block-start: 0em;
}

.fireflyTextLogo {
  margin: 0 auto;
  top: 40vh;
  bottom: 40vh;
}

.quarter {
  width: 5%;
}

.grey {
  color: #424141;
}

.half {
  width: 49%;
  text-align: center;
  padding: 0;
}

.right {
  float: right;
}

center {
  margin: 0 auto;
}

.left {
  float: left;
  text-align: left;
}

h3 {
margin-block-start: 0em;
margin-block-end: 0em;
margin-inline-start: 0px;
margin-inline-end: 0px;
font-weight: bold;
}

.header {
  clear: both;
  height: 80px;
  padding-top: 10px;
}

.FFLogoImg {
  width: 90px;
  height: auto;
}

.headerIcon {
    width: 20px;
    color: #424141;
    margin-right: 20px;
    bottom: 20px;
}


@media only screen and (max-width: 640px) {
  main {
    background-position: 50% -25%;
    background-size: 100%;
  }
  .holdingPageSection {
    color: #424141;
    font-size: 3.6vw;
  }
  .holdingPageSection img.image1{
    display: none;
  }

  .holdingPageSection img.image2{
    display: block;
  }


}